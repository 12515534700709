import axios from "axios";

// const URL=process.env.REACT_APP_BACKEND_URL;
// const API=axios.create({baseURL:'http://127.0.0.1:8000/api/'});

// Base API setup
const API = axios.create({
    baseURL: 'https://masterdashboardapi.basix.in/api/',
    headers: {'Content-type': 'application/json'},
    withCredentials: true, // Ensure cookies are sent with requests
});
 
// Function to refresh the access token
let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed(newAccessToken) {
    refreshSubscribers.map((cb) => cb(newAccessToken));
}

// Function to refresh the access token
const refreshAccessToken = async () => {
    try {
        const response = await axios.post(
            'https://masterdashboardapi.basix.in/api/accounts/token/refresh/',
            {},
            { withCredentials: true }
        );
        const { access } = response.data;

        // Store new access token in localStorage
        localStorage.setItem('access_token', access);

        // Return new access token
        return access;
    } catch (error) {
        console.error("Failed to refresh token:", error.response.data);
        throw error;
    }
};

// Axios request interceptor to attach access token
API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Axios response interceptor to handle 401 errors and refresh token
API.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // If a refresh request is already in progress, queue the request
                return new Promise((resolve) => {
                    subscribeTokenRefresh((newAccessToken) => {
                        // Update the header with the new token and retry the request
                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                        resolve(API(originalRequest));
                    });
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const newAccessToken = await refreshAccessToken();
                isRefreshing = false;
                onRefreshed(newAccessToken);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return API(originalRequest);
            } catch (refreshError) {
                console.error("Failed to refresh access token, logging out.");
                isRefreshing = false;
                refreshSubscribers = [];
                // Handle refresh token expiry (e.g., redirect to login page)
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);


// AUTHENTICATION
export const AUTH_LOGIN_API = (FormData)=>API.post('accounts/user/login/',FormData);
export const AUTH_LOGOUT_API = ()=>API.post('accounts/user/logout/', {},  { withCredentials: true });


// MANAGE  USER 
export const ADD_USER_API = (FormData,config)=>API.post('users/',FormData,config);
export const GET_USERS_API = (FormData,config)=>API.get('user',FormData,config);

// MANAGE TASK 


// company utils
export const company_list_api = ()=>API.get('company/company');
export const department_list_api = (company_id,config)=>API.get(`company/department/${company_id}`,config);
export const position_list_api = (config)=>API.get('company/position',config);

// package calender events 
export const calender_events_api = ()=>API.get('packageList/');
export const packages_and_programs = ()=>API.get('detailsList/');
export const student_api = () => API.get(`detailsUser/`);
export const package_student_api = (packageId)=>API.get(`packageUsers/${packageId}`);
export const package_schedules_api = (packageId)=>API.get(`packageSchedules/${packageId}`);

//package student info
export const student_into_apis = {
    user_transact: (item_id)=>API.get(`userTransact/${item_id}`),
    user_enquiry: (item_id)=>API.get(`userEnquiry/${item_id}`),
    user_workshop: (item_id)=>API.get(`userWorkshop/${item_id}`),
    user_result: (item_id)=>API.get(`userResult/${item_id}`),
    free_mains_detail: (item_id)=>API.get(`freeMainsDetail/${item_id}`),
    free_prelims_detail: (item_id)=>API.get(`freePrelimsDetail/${item_id}`),
    downloads_log_detail: (item_id)=>API.get(`downloadsLogDetail/${item_id}`),
    user_packages: (item_id)=>API.get(`userPackages/${item_id}`),
}

