// PackageContext.js
import React, { createContext, useState, useContext } from 'react';



const PackageContext = createContext();

export const usePackage = () => useContext(PackageContext);

export const PackageProvider = ({ children }) => {
  const [packageId, setPackageId] = useState(null);

  return (
    <PackageContext.Provider value={{ packageId, setPackageId }}>
      {children}
    </PackageContext.Provider>
  );
};
