import React, { useEffect, useState } from 'react'
import { GET_USERS_API } from '../../../helper/Api';
import DataTable from 'react-data-table-component';

import { CustomPagination } from '../../../helper/Snippets';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USER_FAIL,GET_USER_START,GET_USER_SUCCESS,DELETE_USER_START,DELETE_USER_FAIL,DELETE_USER_SUCCESS } from '../../../RTK/Slices/UserSlice';
import {FaRegEdit, FaUserEdit} from "react-icons/fa"
import {MdDelete, MdDeleteForever} from "react-icons/md"
import {Link} from 'react-router-dom';
// import './ManageUser.css';
import { toast } from 'react-toastify';

const UserList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;
    const {user,loading}=useSelector((state)=>state.user);
    const {authData}=useSelector((state)=>state.authData);
    const dispatch=useDispatch();
    const [users,setUsers]=useState(user);

    const fetchUsers=async()=>{
      dispatch(GET_USER_START());
      try {
        const users=await GET_USERS_API();
        if(users.data.status){
          dispatch(GET_USER_SUCCESS(users.data.users))
          setUsers(users.data.users)
        }else{
          dispatch(GET_USER_FAIL(users.data.message))
        }
      } catch (error) {
        dispatch(GET_USER_FAIL('something went wrong'))
      }
    }

    useEffect(() => {
        fetchUsers();
    }, [dispatch])
    // console.log(authData)
    useEffect(() => {
      setUsers(user) 
    }, [user]);
  
    const handleChangePage = (page) => {
      setCurrentPage(page);
    };
  
    const paginatedData = users.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    const customStyles = {
     
      headCells: {
          style: {
              fontWeight:"bold",
              fontSize:"14px"
          },
      },
    };


    const columns = [
      {
        name: 'Empl. Code',
        selector: (row) => row.code,
        sortable: true,
      },
      {
        name: 'Username',
        selector: (row) => row.user_name,
        sortable: true,
        wrap:true
      },
      {
        name: 'Designation',
        selector: (row) => row.designation,
        sortable: true,
        wrap:true
      },
      {
        name: 'Level',
        selector: (row) => row.user_type,
        sortable: true,
        width:"100px"
      },
      {
        name: 'Mobile No',
        selector: (row) => row.mobile,
        sortable: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        wrap:true,
      },
      {
        name: 'Action',
        width:"200px",
        selector: (row) => {
          return (
            <div className='user_action'>
              
            </div>
          );        
        },
        sortable: true,
      }
    ];
  
    const reset_user=async(user_id)=>{
      // console.log(user_id);
      if(user_id){
        const result = window.confirm("Are you sure you want to reset the user");
        if(result){
        //   try {
        //     const {data}=await reset_user_by_id(user_id);
        //     toast.success(data.message,{
        //       position: toast.POSITION.TOP_CENTER
        //     });
        //   } catch (error) {
        //     toast.error("getting error while reset user.",{
        //       position: toast.POSITION.TOP_CENTER
        //     });
        //   }
        }
      }else{
        toast.error("user id required.",{
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  
    const delete_user=async(user_id)=>{
      if(user_id){
        const result = window.confirm("Are you sure you want to delete the user");
        // if(result){
        //   try {
        //     dispatch(DELETE_USER_START());
        //     let formData={
        //       "added_by":authData.user.id
        //     }
        //     const {data}=await delete_user_by_id(formData,user_id);
        //     if(data.status){
        //       dispatch(DELETE_USER_SUCCESS({"id":user_id}));
        //       toast.success(data.message,{
        //         position: toast.POSITION.TOP_CENTER
        //       });
        //     }else{
        //       toast.error(data.message,{
        //         position: toast.POSITION.TOP_CENTER
        //       });
        //     }
            
        //   } catch (error) {
        //     toast.error("getting error while delete user.",{
        //       position: toast.POSITION.TOP_CENTER
        //     });
        //   }
        // }
      }else{
        toast.error("user id required.",{
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  return (
    <div>
        <DataTable
            customStyles={customStyles}
            className="dataTables_wrapper"
            progressPending={loading}
            columns={columns}
            data={paginatedData}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            pagination
            paginationPerPage={rowsPerPage}
            paginationTotalRows={users.length}
            paginationComponent={()=>(
                <CustomPagination
                rowsPerPage={rowsPerPage}
                rowCount={users.length}
                currentPage={currentPage}
                onChangePage={handleChangePage}
            />
            )}
        />
    </div>
  )
}

export default UserList