import React from 'react';
import Plot from 'react-plotly.js';
import {arrangeStageOpprData } from '../../helper/Utils';

class StageOpportunity extends React.Component {
  render() {
    const {stageOpprData}=this.props;
    // console.log(stageOpprData);return false;
    const trace1=arrangeStageOpprData(stageOpprData)
    const data=[trace1];
    const layout = {
      // title: 'Enrolled Student (Mode Wise)',
      xaxis: {
        title: 'Stage',
      },
      yaxis: {
        title: 'Number of Opportunity',
      },
      height:400,
      // barmode: 'group'
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
      />
    );
  }
}
export default StageOpportunity;
