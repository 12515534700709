import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import authReducer from "../Slices/AuthSlice";
import userReducer from "../Slices/UserSlice";


    const persistConfig = {
      key: 'persistLocal',
      storage,
    };
  
  const rootReducer = combineReducers({
    authData: authReducer,
    user: userReducer,
  });
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  export default persistedReducer;