import React, { useEffect, useState } from "react";
import {Button, Card,  Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { AUTH_LOGIN_API, company_list_api } from "../helper/Api";
import { LOGIN_FAIL, LOGIN_START, LOGIN_SUCCESS } from "../RTK/Slices/AuthSlice";
import {useDispatch, useSelector} from 'react-redux'
import { errorToast, successToast } from "../helper/ToastNotification";
import {Loader_spin} from "../components/Spinner/Spinner";
export default function Signin() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {error,loading}=useSelector((state)=>state.authData);

  const [company_list,setCompanyList]=useState(["gsscore"]);
  const [formData,setFormData]=useState({
    email: '',
    password: '',
    // company_id: null,   
  });
  
  const getCompanyList=async()=>{
    try {
      const {data}=await company_list_api();
      if(data.status){
        let list=[];
        data.list.forEach(com => {
          list.push({
            value:com.uid,
            label:com.name,
          })
        });
        setCompanyList(list);
      }else{
        setCompanyList([]);
      }
    } catch (error) {
      console.log(error);
      setCompanyList([]);
    }
  }

  useEffect(() => {
    getCompanyList();
  }, [])

  const handleChange=(e)=>{
    if(e.target){
      const {name,value} = e.target;
      setFormData({...formData,[name]:value})
    }
    else{
      const {value} = e;
      setFormData({...formData,company_id:value})
    }
    // console.log(formData);
  }

  const handleSubmit=async(e)=>{
    e.preventDefault();
    
    try {
      dispatch(LOGIN_START());
      const {data}=await AUTH_LOGIN_API(formData);
      
      if(data){
        // console.log(data);
        localStorage.setItem('access_token', data.token);
        dispatch(LOGIN_SUCCESS(data));
        successToast('login success') 
        navigate('/dashboard/finance');
      }else{
        errorToast('something went wrong');
      }
    } catch (e) {
      let {message}=e.response.data;
      dispatch(LOGIN_FAIL(message))
      if(message.non_field_errors){
        errorToast(message.non_field_errors[0])
      }
    } 
  }
  
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link className="header-logo mb-4">GSSCORE</Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form method="post" onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label >Email address</Form.Label>
              <Form.Control type="email" name="email" onChange={handleChange} placeholder="Enter your email address" value={formData.email} required/>
              <Card.Text className="text-danger">{error&&error['email']?error['email'][0]:""}</Card.Text>
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between" htmlFor="password">Password <Link to="">Forgot password?</Link></Form.Label>
              <Form.Control type="password" name="password" onChange={handleChange} placeholder="Enter your password" value={formData.password} required />
              <Card.Text className="text-danger">{error&&error['password']?error['password'][0]:""}</Card.Text>
            </div>
            <div className="mb-4">              
              <Select options={company_list}
                onChange={handleChange}  isSearchable={true} placeholder="--- Select Company ---" name="company_id"
                // required
              />
              {/* <Select 
                placeholder="--- Select Company ---" name="company_id"
              /> */}
            </div>
            <Button type={loading?"button":'submit'} variant="primary" className="btn-sign">{loading?Loader_spin('warning','Loading'):"Sign In"}</Button>
            {/* <div className="divider"><span>or sign in with</span></div>
            <Row className="gx-2">
              <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
              <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
            </Row> */}
          </Form>
        </Card.Body>
        <Card.Footer>
          {/* Don't have an account? <Link to="/pages/signup">Create an Account</Link> */}
        </Card.Footer>
      </Card>
    </div>
  )
}