import axios from "axios";
// DASHBOARD - IASSCORE -DELHI  
const IASSCORE_API=axios.create({baseURL:'https://iasscore.in/api/'});

// export const REVENUE_COURSES_API = (form_data)=>IASSCORE_API.post('',form_data);

export const REVENUE_COURSES_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/iasscore_dashboard/admission_amount_of_courses_location_wise',FormData,config);

export const NUMBER_OF_RUNNING_COURSES_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/iasscore_dashboard/get_running_courses',FormData,config);

export const NUMBER_OF_ENROLLED_STUDENT_COURSES_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/iasscore_dashboard/student_enrolled_courses',FormData,config);
export const NUMBER_OF_ENROLLED_STUDENT_MODE_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/iasscore_dashboard/student_enrolled_in_courses__mode_wise',FormData,config);

export const NUMBER_OF_EVENTS_TEACHER_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/iasscore_dashboard/get_teacher_schedule_events',FormData,config);
export const COPYTRACKING_LIST_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/iasscore_dashboard/get_copytracking_list',FormData,config);

// LEAD SQUARE API 
export const GET_LEADS_COUNSELLOR_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/Lead_square/created_leads_counsellor_wise',FormData,config);
export const GET_LEADS_LEAD_SOURCE_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/Lead_square/created_leads_lead_source_wise',FormData,config);

export const GET_LEADS_OPPR_COUNSELLOR_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/Lead_square/get_leads_opportunity_counsellor_wise',FormData,config);
export const GET_LEADS_OPPR_SOURCE_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/Lead_square/get_leads_opportunity_source_wise',FormData,config);
export const GET_LEADS_OPPR_STATUS_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/Lead_square/get_leads_opportunity_status_wise',FormData,config);
export const GET_LEADS_OPPR_STAGE_WISE_API = (FormData,config)=>IASSCORE_API.post('/master_dashboard/Lead_square/get_leads_opportunity_stage_wise',FormData,config);

export const testing_api = ()=>IASSCORE_API.get('master_dashboard/iasscore_dashboard');



