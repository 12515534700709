import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Col, Row, Table, Collapse, Container, FormControl, InputGroup, Form, Spinner } from "react-bootstrap";
import { FaSearch, FaChevronUp, FaChevronDown } from "react-icons/fa"; // Import the search icon
import { packages_and_programs } from "../../helper/Api";
import Pagination from "../../dashboard/Pagination"; // Import the Pagination component
import { usePackage } from '../../context/PackageContext'; // Import the usePackage hook

function createData(id, title, paidStudents, batch_start, valid_till, programs) {
  return {
    id,
    title,
    paidStudents,
    batch_start,
    valid_till,
    programs: programs?.map(program => ({
      title: program.title,
      batch_start: program.batch_start,
      program_code: program.program_code,
      course_hours: program.course_hours
    }))
  };
}

function RowComponent({ row, index, scrollToStudentInfo }) {
  const [open, setOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const { setPackageId } = usePackage(); // Get setPackageId function from context

  const handleShowStudents = () => {
    setPackageId(row.id); // Set the packageId in context
    scrollToStudentInfo(); // Scroll to StudentInfo component
  };

  useEffect(() => {
    setIsDisable(row.paidStudents <= 0);
  }, [row.paidStudents]);

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{row.title}</td>
        <td>{row.paidStudents}</td>
        <td className="text-right">{row.batch_start ? new Date(row.batch_start).toLocaleDateString('en-GB') : 'N/A'}</td>
        <td className="text-right">{row.valid_till ? new Date(row.valid_till).toLocaleDateString('en-GB') : 'N/A'}</td>
        <td className="d-flex ">
          <Button
            variant="primary"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            size="sm"
            className="me-2"
          >
            {open ? 'Hide Programs' : 'Show Programs'}
          </Button>
          <Button
            disabled={isDisable}
            variant="primary"
            onClick={handleShowStudents}
            size="sm"
          >
            Show Students
          </Button>
        </td>
      </tr>
      <tr>
        <td colSpan="7" style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open}>
            <div>
              <Container fluid>
                <Row>
                  <Col>
                    <h6>Programs</h6>
                    <Table size="sm" aria-label="programs">
                      <thead>
                        <tr>
                          <th>S.no.</th>
                          <th>Title</th>
                          <th>Program Start Date</th>
                          <th className="text-right">Course Hours</th>
                          <th className="text-right">Program Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.programs && row.programs.length > 0 ? (
                          row.programs.map((program, index) => (
                            <tr key={program.program_code}>
                              <td>{index + 1}</td>
                              <td>{program.title}</td>
                              <td>{program.batch_start ? new Date(program.batch_start).toLocaleDateString('en-GB') : 'N/A'}</td>
                              <td className="text-right">{program.course_hours}</td>
                              <td className="text-right">{program.program_code}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center" style={{ color: "#506FD9" }}>
                              There are no programs available for this package
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
}


const PackagesAndPrograms = ({ scrollToStudentInfo }) => {  // Accept scrollToStudentInfo as a prop
  // Your component's state and logic
  const [data_packages, setData_packages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(true); // New state for loading
  const itemsPerPage = 25;  
                                                                     
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const get_packages_and_programs = useCallback(async () => {
    setLoading(true); // Set loading to true when starting to fetch data
    const { data } = await packages_and_programs();
    setData_packages(data);
    setLoading(false); // Set loading to false when data is fetched
  }, []);
  

  useEffect(() => {
    get_packages_and_programs();
  }, [get_packages_and_programs]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const today = new Date();
    const last10Days = new Date();
    last10Days.setDate(today.getDate() - 10);

    setEndDate(today.toISOString().split('T')[0]);
    setStartDate(last10Days.toISOString().split('T')[0]);
  }, []);

  const filteredData = data_packages.filter(pkg => {
    const batchStartDate = new Date(pkg.batch_start);
    const start = new Date(startDate);
    const end = new Date(endDate);

    const isWithinDateRange = (!startDate || !endDate || (batchStartDate >= start && batchStartDate <= end));
    return pkg.title.toLowerCase().includes(searchTerm.toLowerCase()) && isWithinDateRange;
  });

  filteredData.sort((a, b) => new Date(b.batch_start) - new Date(a.batch_start));

  // Calculate the indices for slicing the data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
        <div className="package-div">
          <Row className="g-3">
              <Col xl="12">
                  <div className="d-flex justify-content-between align-items-center">
                    <Button onClick={toggleExpand} variant="primary" className="mb-4">
                      {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                      {isExpanded ? 'Minimize' : 'Expand'} Table
                    </Button>
                  </div>
              </Col>
          </Row>
          
          {loading ? ( // Show loader if data is still being fetched
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : isExpanded ? (
            <>
              <Row className="g-3 mb-4 align-items-center">
                <h5 className="mb-3 fw-bold">Packages & Programs Overview</h5>
                <Col xl="8">
                  <InputGroup className="mb-3" style={{ maxWidth: '400px' }}>
                    <FormControl
                      placeholder="Search by title"
                      aria-label="Search by title"
                      aria-describedby="basic-addon2"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <FaSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col xl="2">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{ maxWidth: '150px' }}
                    />
                  </Form.Group>
                </Col>
                <Col xl="2">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{ maxWidth: '150px' }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-3">
                <Col xl="12">
                  <Card className="card-one p-3">
                    <Card.Body className="overflow-hidden px-0 pb-3">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Title</th> 
                            <th>Paid Student</th> 
                            <th className="text-right">Start Date</th>
                            <th className="text-right">Valid Till</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((row, index) => (
                            <RowComponent key={row.id} row={row} index={index} scrollToStudentInfo={scrollToStudentInfo} />
                          ))}
                        </tbody>
                      </Table>

                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card className="p-3">
              <h5 className="mb-3 fw-bold">Packages & Programs Overview</h5>
              <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Title</th>
                        <th>Paid Student</th>
                        <th className="text-right">Start Date</th>
                        <th className="text-right">Valid Till</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.slice(0, 4).map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.title}</td>
                          <td>{row.paidStudents}</td>
                          <td className="text-right">{row.batch_start ? new Date(row.batch_start).toLocaleDateString('en-GB') : 'N/A'}</td>
                          <td className="text-right">{row.valid_till ? new Date(row.valid_till).toLocaleDateString('en-GB') : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          )}
        </div>
  );
}

export default PackagesAndPrograms