import React from 'react';
import Plot from 'react-plotly.js';
import { arrangeTeacherEventData } from '../../helper/Utils';

class TeacherScheduleEvent extends React.Component {
  render() {
    const {teacherEventData}=this.props;
    // console.log(teacherEventData);
    const data = arrangeTeacherEventData(teacherEventData);
    // console.log(data);
    const layout = {
      // title: 'Enrolled Student (Mode Wise)',
      xaxis: {
        title: 'Teacher/Mentor',
      },
      yaxis: {
        title: 'Number of Events',
      },
      height:400,
      barmode: 'group'
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '90%' }}
      />
    );
  }
}
export default TeacherScheduleEvent;
