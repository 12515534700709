import React, { useEffect, useState, useCallback } from "react";
import { Card, Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";  // For week and day views
import { package_schedules_api } from "../../helper/Api";
import "../../assets/css/packages-calender.css";

// Helper function to format time into 12-hour format without any timezone adjustments
const formatTime = (dateTimeString) => {
  // Parse the date string directly
  const date = new Date(dateTimeString);

  // Extract hours and minutes directly
  let hours = date.getUTCHours();  // UTC hours without timezone adjustment
  let minutes = date.getUTCMinutes();

  // Convert to 12-hour format
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  // Pad minutes with leading zero if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${formattedMinutes} ${period}`;
};

const adjustTimeByOffset = (dateTimeString) => {
  // Create a Date object from the original datetime string
  const date = new Date(dateTimeString);

  // Subtract 5 hours and 30 minutes (330 minutes) from the date
  date.setMinutes(date.getMinutes() - 330); // 330 minutes = 5 hours 30 minutes

  return date.toISOString(); // Return the adjusted date in ISO format
};

const PackageSchedules = ({ packageId, handleBackButton, packageTitle }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCalenderEvents = useCallback(async () => {
    setLoading(true); // Optional: Set loading state
    try {
      const { data } = await package_schedules_api(packageId);
      // console.log(data)
      const events = data.map(scheduleData => {
          const startDate = adjustTimeByOffset(scheduleData.start_date);  // Adjust start date
          const endDate = adjustTimeByOffset(scheduleData.end_date[0]);   // Adjust end date
          // console.log(scheduleData.start_date)
          // Formatting the time from the Date object
          const startTime = formatTime(scheduleData.start_date); // Custom format function
          const endTime = formatTime(scheduleData.end_date[0]);  // Custom format function  
          console.log(startTime)
          return {  
            title: `${scheduleData.title}: ${startTime} - ${endTime}`, // Format title with time range
            // title: scheduleData.title, // Format title with time range
            start: startDate,
            end: endDate,
            status: scheduleData.status, // Include status for conditional styling
          };
      });
      setEvents(events);
    }
    catch (e) {
        console.log(e);
    }
    finally {
        setLoading(false); // Optional: Reset loading state
    }
  }, [packageId])

  useEffect(() => {
      getCalenderEvents();  
  }, [getCalenderEvents]);

  const renderEventContent = (eventInfo) => {
    const { status } = eventInfo.event.extendedProps;
    const title = eventInfo.event.title; // title is a direct property of event
    // console.log(status, title)
    // Set the color based on the event status
    let color = '#3788d8'; // Default color for status 1
    if (status === 2) {
      color = 'red';
    } else if (status === 3) {
      color = 'yellow';
    }
    return (
      <div title={title}  style={{ whiteSpace: "normal", background: color, color: "white", cursor: "pointer"}} >
        {title}
      </div>
    );
  };

  return (
        <div className="calender-div mb-5 mt-5">
            <Row className="g-3">
              <Col xl="12">
                <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                  <h5 className="fw-bold">Package Schedules ({packageTitle})</h5>
                  <button onClick={handleBackButton} className="btn btn-primary">Back</button>
                </div>
              </Col>
            </Row>
            {/* Legend for event statuses */}
            <Row className="g-3">
              <Col xl="12">
                <div className="d-flex mb-3">
                  <div style={{ display: 'flex', alignItems: 'center', marginRight:"10px" }}>
                    <span style={{ background: 'blue', width: '20px', height: '20px', display: 'inline-block', marginRight: '10px' }}></span>
                    <span>Active</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight:"10px" }}>
                    <span style={{ background: 'red', width: '20px', height: '20px', display: 'inline-block', marginRight: '10px' }}></span>
                    <span>Cancelled</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight:"10px" }}>
                    <span style={{ background: 'yellow', width: '20px', height: '20px', display: 'inline-block', marginRight: '10px' }}></span>
                    <span>Rescheduled</span>
                  </div>
                </div>
              </Col>
            </Row>
            
            <Row className="g-3">
                <Col xl="12">
                    <Card className="card-one p-3">
                        <Card.Body className="overflow-hidden px-0 pb-3">
                          <div>
                              <FullCalendar
                                  defaultView="dayGridMonth"
                                  headerToolbar={{
                                    left: "prev,next today", // Navigation buttons
                                    center: "title",         // Calendar title
                                    right: "dayGridMonth,timeGridWeek,timeGridDay",  // View options
                                  }}
                                  plugins={[dayGridPlugin, timeGridPlugin]} // Add plugins for multiple views
                                  initialView="dayGridMonth"  // Set default view as month view
                                  themeSystem="Simplex"
                                  events={events}
                                  eventContent={renderEventContent} // Custom event content
                                  eventClassNames={(event) => {
                                      return ['custom-event'];
                                  }}                  
                              />
                          </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
  );
}

export default PackageSchedules;