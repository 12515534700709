import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import "../assets/css/packages-calender.css";
import { all_center_list } from "../helper/Utils";
import { PackageProvider } from '../context/PackageContext'; // Import the PackageProvider
import PackageProgram from '../components/CourseDelivery/PackageProgram';
import StudentInfo from '../components/CourseDelivery/StudentInfo';
import PackagesCalendar from '../components/CourseDelivery/PackagesCalendar';
import CopyTrackingChart from "../components/Bar/CopyTrackingChart";
import TeacherScheduleEvent from "../components/Bar/TeacherScheduleEvent";
import { COPYTRACKING_LIST_API, NUMBER_OF_EVENTS_TEACHER_WISE_API } from "../helper/Iasscore_api";
import { ApiLoader } from "../components/Spinner/Spinner";
import PackageSchedules from '../components/CourseDelivery/PackageSchedules';

const CourseDelivery = () => {
  let center_list = all_center_list();
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const studentInfoRef = useRef(null); // Create a ref for StudentInfo
  let currentDate = new Date(); // current date and time
  const max_date = currentDate.toISOString().split('T')[0];
  currentDate.setDate(currentDate.getDate() - 2);
  const min_date = currentDate.toISOString().split('T')[0];
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [selectedPackageTitle, setSelectedPackageTitle] = useState(''); // Store selected package title

  // Function to handle the selected package ID
  const handlePackageSelect = useCallback((packageId, packageTitle) => {
    setSelectedPackageId(packageId);
    setSelectedPackageTitle(packageTitle); // Update the selected package title
  }, []);
  
  // Function to handle back button
  const handleBackButton = useCallback(() => {
    setSelectedPackageId(null);
  }, []);

  const [teacherEventFilter, setTeacherEventFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })

  const [copyTrackingFilter, setcopyTrackingFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })

  // data store state
  const [teacherEventData, setTeacherEventData] = useState([]);
  const [copyTrackingData, setCopyTrackingData] = useState([]);
  //loader state
  const [scheduleEventLoading,setScheduleEventLoading]=useState(false);
  const [copyTrackingLoading,setCopyTrackingLoading]=useState(false);

  const scrollToStudentInfo = () => {
    if (studentInfoRef.current) {
      studentInfoRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to StudentInfo
    }
  };                                                                   

  const switchSkin = (skin) => {
    const btnClass = skin === 'dark' ? 'btn-outline-primary' : 'btn-white';
    const btnToUpdate = document.getElementsByClassName(skin === 'dark' ? 'btn-white' : 'btn-outline-primary');
    for (const btn of btnToUpdate) {
      btn.classList.add(btnClass);
      btn.classList.remove(skin === 'dark' ? 'btn-white' : 'btn-outline-primary');
    }
  };

  const get_events_teacher_wise = useCallback(async () => {
    setScheduleEventLoading(true);
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await NUMBER_OF_EVENTS_TEACHER_WISE_API(teacherEventFilter, config);
      setScheduleEventLoading(false);
      if (data.status) {
        setTeacherEventData(data.list)
      } else {
        setTeacherEventData([])
      }
    } catch (e) {
      setScheduleEventLoading(false);
      setTeacherEventData([])
      console.log(e);
    }
  }, [teacherEventFilter])

  const get_copytracking_list = useCallback(async () => {
    setCopyTrackingLoading(true)
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await COPYTRACKING_LIST_API(copyTrackingFilter, config);
      setCopyTrackingLoading(false)
      if (data.status) {
        setCopyTrackingData(data.list)
      } else {
        setCopyTrackingData([])
      }
    } catch (e) {
      setCopyTrackingLoading(false)
      setCopyTrackingData([])
      console.log(e);
    }
  }, [copyTrackingFilter])

  useEffect(() => {
    get_events_teacher_wise();
  }, [get_events_teacher_wise])

  useEffect(() => {
    get_copytracking_list();
  }, [get_copytracking_list])

  const handleTeacherEventFilterchange=(e)=>{
    const { name, value } = e.target;
    setTeacherEventFilter({ ...teacherEventFilter, [name]: value })
  }

  const handleCopyTrackingFilterchange=(e)=>{
    const { name, value } = e.target;
    setcopyTrackingFilter({ ...copyTrackingFilter, [name]: value })
  }

  const total_teacher_event=()=>{
    let total=0;
    let total_classes=0,total_discussion=0,total_activity=0
    teacherEventData.forEach(li => {
        const {classes,discussion,activity}=li;
        total_classes+=parseInt(classes)
        total_discussion+=parseInt(discussion)
        total_activity+=parseInt(activity)
    });
    total=total_classes+total_discussion+total_activity
    let result={
      total_classes,
      total_discussion,
      total_activity,
      total
    }
    return result;
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
        <Header onSkin={setSkin} />
        <div className="main main-app p-0 p-lg-3">
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Course Delievery</li>
                    </ol>
                    <h4 className="main-title mb-0">Welcome to Course Delievery</h4>
                </div>
                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="" className="btn-white d-flex align-items-center gap-2">
                        <i className="ri-share-line fs-18 lh-1"></i>Share
                    </Button>
                    <Button variant="" className="btn-white d-flex align-items-center gap-2">
                        <i className="ri-printer-line fs-18 lh-1"></i>Print
                    </Button>
                    <Button variant="primary" className="d-flex align-items-center gap-2">
                        <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
                    </Button>
                </div>
            </div>
            {!selectedPackageId ? (
              <>

                  <Row className="g-3">
                      <Col xl="6">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">Teacher Schedule Events : <span className="text-danger">(Classes:{total_teacher_event().total_classes}, Discussion:{total_teacher_event().total_discussion},  Activity: {total_teacher_event().total_activity}): <span className="text-dark">
                            Total  {total_teacher_event().total}</span>
                            </span> : 
                            </Card.Title>
                            
                          </Card.Header>
                          <Card.Body className="p-2 pt-1">
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>From:</Form.Label>
                                  <Form.Control type="date" placeholder="" value={teacherEventFilter.start_date} name="start_date" onChange={handleTeacherEventFilterchange} max={max_date} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>End:</Form.Label>
                                  <Form.Control type="date" placeholder=""  value={teacherEventFilter.end_date} name="end_date" onChange={handleTeacherEventFilterchange} max={max_date} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>Center:</Form.Label>
                                  <Form.Select name="centre_id" onChange={handleTeacherEventFilterchange}>
                                    <option value="">All Center</option>
                                    {
                                      center_list.map((center) => {
                                        // console.log(center);
                                        return (
                                          <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                                        );
                                      })
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            {scheduleEventLoading ? 
                              <div className="text-center">
                                <ApiLoader color="danger" text="Loading"/>
                              </div>
                              :<TeacherScheduleEvent teacherEventData={teacherEventData}/>
                            }
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xl="6">
                        <Card className="card-one">
                          <Card.Header>
                            <Card.Title as="h6">Copy Tracking : </Card.Title>
                          </Card.Header>
                          <Card.Body className="p-2 pt-1">
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>From:</Form.Label>
                                  <Form.Control type="date" placeholder="" value={copyTrackingFilter.start_date} name="start_date" onChange={handleCopyTrackingFilterchange} max={max_date} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>End:</Form.Label>
                                  <Form.Control type="date" placeholder=""  value={copyTrackingFilter.end_date} name="end_date" onChange={handleCopyTrackingFilterchange} max={max_date} />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>Center:</Form.Label>
                                  <Form.Select name="centre_id" onChange={handleCopyTrackingFilterchange}>
                                    <option value="">All Center</option>
                                    {
                                      center_list.map((center) => {
                                        // console.log(center);
                                        return (
                                          <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                                        );
                                      })
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            {copyTrackingLoading ? 
                            <div className="text-center">
                              <ApiLoader color="danger" text="Loading"/>
                            </div>
                            :<CopyTrackingChart copyTrackingData={copyTrackingData}/>
                            }                
                          </Card.Body>
                        </Card>
                      </Col>
                  </Row>
                  
                  {/* Render the PackagesCalendar when no package is selected */}
                  <PackagesCalendar onPackageSelect={handlePackageSelect} />
                  <PackageProvider>
                      <PackageProgram scrollToStudentInfo={scrollToStudentInfo} />
                      <div ref={studentInfoRef}> {/* Add ref here */}
                        <StudentInfo />
                      </div>
                  </PackageProvider>
              </>
            ) : (
              // Render the PackageSchedule component when a package is selected
              <PackageSchedules packageId={selectedPackageId} packageTitle={selectedPackageTitle} handleBackButton={handleBackButton} />
            )}
            <Footer />
        </div>
    </React.Fragment>
  );
}

export default CourseDelivery