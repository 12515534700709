import React from 'react';
import Plot from 'react-plotly.js';
import {arrangeStatusOpprData } from '../../helper/Utils';

class StatusOpportunityPie extends React.Component {
  render() {
    const {statusOpprData}=this.props;    
    let trace1=arrangeStatusOpprData(statusOpprData)
    var data = [trace1];
    console.log(data);
    const layout = {
        // title: 'Enrolled Student (Course Type Wise)',
        xaxis: {
            title: 'Status of Opportunity',
        },
        yaxis: {
            title: 'Number of Opportunity',
        },
        height:400,
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
      />
    );
  }
}
export default StatusOpportunityPie;
