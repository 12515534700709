import Pagination from 'react-bootstrap/Pagination';

export const CustomPagination = ({ rowsPerPage, rowCount, currentPage, onChangePage }) => {
    const totalPages = Math.ceil(rowCount / rowsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  
    const paginationNumber = () => {
      if(pageNumbers.length <= 5){
        return pageNumbers;
      }else if(pageNumbers.length >= 5 && currentPage <= 4){
        return [1,2,3,4,5,'...',pageNumbers[pageNumbers.length - 1]];
      }else if(pageNumbers.length >= 5 && currentPage >= pageNumbers[pageNumbers.length - 4]){
        return [1,'...',pageNumbers[pageNumbers.length - 5],pageNumbers[pageNumbers.length - 4],pageNumbers[pageNumbers.length - 3],pageNumbers[pageNumbers.length - 2],pageNumbers[pageNumbers.length - 1]];
      }else if(pageNumbers.length > 5 && currentPage > 4 && currentPage < pageNumbers[pageNumbers.length - 4]){
        return [1,'...',currentPage-1,currentPage,currentPage+1,'...',pageNumbers[pageNumbers.length - 1]];
      }
    };
    let paginationItms = paginationNumber();
  
    return (
      <div className='pt-3'>
        <span className='pb-2'>Total Rows: <b>{rowCount}</b></span>
        <Pagination className='mt-2'>
        {paginationItms.map((pageNumber,i) => (
          <Pagination.Item
            key={i}
            onClick={() => onChangePage(pageNumber)}
            disabled={(isNaN(pageNumber))}
            active={(currentPage === pageNumber)}
          >
            {pageNumber}
            </Pagination.Item>
        ))}
         </Pagination>
      </div>
    );
  };
