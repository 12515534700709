import React from "react";
import { Outlet,Navigate, useNavigate} from "react-router-dom";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";


export default function Main({authData}) {
  const navigate=useNavigate();
  const { pathname } = useLocation();
  if(authData==null){
    return <Navigate to="/signin" replace />;
  }
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const bc = document.body.classList;
  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");
  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");
  // scroll to top when switching pages
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Sidebar  authData={authData} navigate={navigate}/>
      <Outlet />
    </React.Fragment>
  )
}