import React from 'react';
import Plot from 'react-plotly.js';
import {  } from '../../helper/Utils';

class CopyTrackingChart extends React.Component {
  render() {



    const {copyTrackingData}=this.props;
    const {received_from_student,assign_to_faculty,receive_from_faculty,assign_to_reviewer,receive_from_reviewer,receive_from_reviewer_final,one_to_one_discussion_schedule}=copyTrackingData;
    let copy_count=[received_from_student,assign_to_faculty,receive_from_faculty,assign_to_reviewer,receive_from_reviewer,receive_from_reviewer_final,one_to_one_discussion_schedule];
    
    const data=[
      {        
        x: ['received_from_student', 'assign_to_faculty', 'receive_from_faculty','assign_to_reviewer','receive_from_reviewer','receive_from_reviewer_final','one_to_one_discussion_schedule'],
        y: copy_count,
        type: 'bar',
        text: copy_count.map(String),
        marker:{
          color:['#0766AD','#7071E8','#1640D6','#39A7FF',"#87C4FF",'#B31312',"#0C356A"]
        }
      }
    ];

    const layout = {
      // title: 'Enrolled Student (Mode Wise)',
      xaxis: {
        title: 'Copy Status Type',
      },
      yaxis: {
        title: 'Number of Copy',
      },
      height:400,
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '90%' }}
      />
    );
  }
}
export default CopyTrackingChart;
