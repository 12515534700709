import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import { FaSearch, FaChevronUp, FaChevronDown } from "react-icons/fa"; // Import the search icon
import dayGridPlugin from "@fullcalendar/daygrid";
import { calender_events_api } from "../../helper/Api";
import "../../assets/css/packages-calender.css";

const PackagesCalendar = ({ onPackageSelect }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // State for calendar height
  const [selectedPackageTitle, setSelectedPackageTitle] = useState(""); // Selected title
  const [packageMap, setPackageMap] = useState(new Map()); // Use a Map for packageId lookup

  const getCalenderEvents = useCallback(async () => {
    setLoading(true); // Optional: Set loading state
    try {
      const { data } = await calender_events_api();
      // Create a Map for package titles and IDs
      const packageMap = new Map();
      const events = data.map((packageData) => {
        packageMap.set(packageData.title, packageData.id);
        return {
          id: packageData.id, // Store package ID
          title: packageData.title,
          start: packageData.batch_start,
        };
      });
      setEvents(events);
      // Filter only packages with paidStudents > 0 for the datalist
      const filteredPackageMap = new Map();
      data.filter((packageData) => packageData.paidStudents > 0)
          .forEach((packageData) => {
            filteredPackageMap.set(packageData.title, packageData.id); // Map filtered titles
      });
      setPackageMap(filteredPackageMap); // Set the filtered packages for the datalist
    }
    catch (e) {
        console.log(e);
    }
    finally {
        setLoading(false); // Optional: Reset loading state
    }
  }, [])

  useEffect(()=>{
    getCalenderEvents()
  }, [getCalenderEvents])

  // Handle calendar event click
  const handleEventClick = (eventInfo) => {
    const packageId = eventInfo.event.id;
    const packageTitle = eventInfo.event.title; // Get package title from the clicked event
    onPackageSelect(packageId, packageTitle); // Pass the packageId to the parent component
  };

  // Handle submit for selected package
  const handleSelectSubmit = () => {
    const packageId = packageMap.get(selectedPackageTitle);
    if (packageId) {
      onPackageSelect(packageId, selectedPackageTitle); // Pass the packageId to the parent component
    } else {
      console.log("Selected package not found.");
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div title={eventInfo.event.title} style={{ whiteSpace: "normal", background:"#3788d8", color:"white", cursor:"pointer"}} >
        {eventInfo.event.title}
      </div>
    );
  };

  return (
        <div className="calender-div mb-5 mt-5">
          <Row className="g-3">
              <Col xl="12">
                <div className="d-flex justify-content-between align-items-center">
                  <Button variant="primary" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                    {isExpanded ? 'Minimize Table' : 'Expand Table'}
                  </Button>
                </div>
              </Col>
          </Row>
            
          <Row className="g-3">
            <Col xl="12">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mt-3 mb-3 fw-bold">Package Calendar</h5>
              </div>
            </Col>
          </Row>
      
            <Form>
                {/* Package Datalist */}
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Select Package</Form.Label>
                  <InputGroup>
                    <Form.Control
                      list="packageList"
                      placeholder="Select a package"
                      value={selectedPackageTitle}
                      onChange={(e) => setSelectedPackageTitle(e.target.value)} // Set selected package title
                    />
                    <InputGroup.Text id="basic-addon2">
                      <FaSearch />
                    </InputGroup.Text>
                    <datalist id="packageList">
                      {[...packageMap.keys()].map((packageTitle) => (
                        <option key={packageTitle} value={packageTitle}>
                          {packageTitle}
                        </option>
                      ))}
                    </datalist>
                  </InputGroup>
                </Form.Group>
                <Button onClick={handleSelectSubmit} className="mb-2">Submit</Button>
            </Form>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card-one p-3">
                        <Card.Body className="overflow-hidden px-0 pb-3">
                          <div
                              style={{
                                height: isExpanded ? "auto" : "300px", // Conditional height
                                transition: "height 0.3s ease", // Smooth transition
                              }}
                          >
                              <FullCalendar
                                  defaultView="dayGridMonth"
                                  header={{
                                      left: "prev,next",
                                      center: "title",
                                      right: "dayGridMonth,timeGridWeek,timeGridDay"
                                  }}
                                  themeSystem="Simplex"
                                  plugins={[dayGridPlugin]}
                                  events={events}
                                  eventClick={handleEventClick} // Handle package click
                                  eventContent={renderEventContent} // Custom event content
                                  eventClassNames={(event) => {
                                      return ['custom-event'];
                                  }}                  
                              />
                          </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
  );
}

export default PackagesCalendar;